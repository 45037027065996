import type DataObject from 'o365.modules.DataObject.ts';
import type { DataObjectDefinitionFieldType, FieldType } from 'o365.modules.DataObject.Fields.ts';
import type { RecordSourceOptions, ItemModel, RecordSourceCancelableEvent } from 'o365.modules.DataObject.Types.ts';

import { applyFilterObject } from 'o365.modules.filterUtils.ts';
import api from 'o365.modules.data.api.ts';
import alert from 'o365.controls.alert.ts';

/** Base abstact class that all data handlers must implement */
export interface IDataHandler<T extends ItemModel = ItemModel> {
    utc: boolean;
    /** Optional function that is called by DataObjects when initializing the DataHandler */
    setDataObject?: (pDataObject: DataObject<T>) => void;
    /** Optional function that is called by DataObjects when client side filtering is enabled */
    setClientSideData?: (pData: T[]) => void;
    retrieve(pOptions: RecordSourceOptions & Partial<IRetrieveOptions<T>>): Promise<T[]>;
    create(pData: RecordSourceOptions & Partial<ICreateOptions>): Promise<T[]>;
    update(pData: RecordSourceOptions & Partial<IUpdateOptions>): Promise<T[]>;
    destroy(pData: RecordSourceOptions & Partial<IDestroyOptions>): Promise<boolean>;
    softDelete(pData: RecordSourceOptions & Partial<IUpdateOptions>): Promise<boolean>;
    rowCount(pData: RecordSourceOptions & Partial<IRowCountOptions>): Promise<{ skip: number, total: number }>;
    distinct(pOptions: RecordSourceOptions & IDistinctOptions): Promise<(T & { Count: number })[]>;

    getAbortControllerForRequest?<T extends IRequestOptions & RecordSourceOptions>(pOptions: T): AbortController | undefined
}

/**
 * Default DataHandler for DataObjects.
 * Returns raw data
 */
export default class DataHandler<T extends ItemModel = ItemModel> implements IDataHandler<T> {
    protected _dataObject: DataObject<T>;
    protected _url = '/nt/api/data';
    protected _streamUrl = '/nt/api/data/stream';
    utc = false;
    /** Map of currently active reuqest abort controllers */
    protected _requests: Map<string, AbortController> = new Map();
    protected _clientSideData: any[] = [];
    groupFormatFunction?: (pData: T[]) => T[] | Promise<T[]>;
    doNotSaveToDB: boolean = false;

    /** Raw data from the API response used for client side filtering */
    get clientSideData() { return this._clientSideData; }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        if (pDataObject?.viewName) {
            const requestPath = pDataObject.id ? `${pDataObject.id}-${pDataObject.viewName}` : pDataObject.viewName;
            this._url = `/nt/api/data/${requestPath}`;
            this._streamUrl = `/nt/api/data/stream/${requestPath}-stream`;
        }
    }

    updateViewName(pViewName:string){
        if (pViewName) {
            this._url = `/nt/api/data/${pViewName}`;
            this._streamUrl = `/nt/api/data/stream/${pViewName}-stream`;
        }
    }

    async retrieve(pOptions: RecordSourceOptions & Partial<IRetrieveOptions<T>>) {
        if (this._dataObject?.clientSideFiltering && this._dataObject?.state.isLoaded && !pOptions.skipClientSideHandlerCheck) {
            // Filter and return data from the stored client side data
            let data = this._clientSideFilter(pOptions, this._clientSideData);
            this._sortData(pOptions, data);

            // Compatibility for previous grouping modules 
            if (this.groupFormatFunction) {
                const groupResponse = this.groupFormatFunction(data);
                if (groupResponse instanceof Promise) {
                    data = await groupResponse;
                } else {
                    data = groupResponse;
                }
            }

            return data;
        }

        if (!this.utc && pOptions) {
            pOptions.timezoneOffset = pOptions?.timezoneOffset ?? -(new Date().getTimezoneOffset());
            pOptions.timezoneName = pOptions?.timezoneName ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

        }

        // Make sure to disable load recents when loading pages
        if (pOptions && pOptions.skip && pOptions.skip > 0 && pOptions.loadRecents === true) {
            pOptions.loadRecents = false;
        }

        let apiResponse: any[] = await this.request('retrieve', pOptions ?? {});
        this._clientSideData = apiResponse;

        // Compatibility for previous grouping modules 
        if (this.groupFormatFunction) {
            const groupResponse = this.groupFormatFunction(apiResponse);
            if (groupResponse instanceof Promise) {
                apiResponse = await groupResponse;
            } else {
                apiResponse = groupResponse;
            }
        }

        return apiResponse;
    }

    async create(pData: RecordSourceOptions & Partial<ICreateOptions>) {
        if (this.doNotSaveToDB) {
            return [pData.values];
        }
        return await this.request('create', pData);
    }

    async update(pData: RecordSourceOptions & Partial<IUpdateOptions>) {
        return await this.request('update', pData);
    }
    async softDelete(pData: RecordSourceOptions & Partial<IUpdateOptions>) {
        return await this.request('softDelete', pData);
    }

    async destroy(pData: RecordSourceOptions & Partial<IDestroyOptions>) {
        return await this.request('destroy', pData);
    }

    async rowCount(pData: RecordSourceOptions & Partial<IRowCountOptions>) {
        if (!this.utc) {
            pData.timezoneOffset = new Date().getTimezoneOffset();
        }
        if (this._dataObject.clientSideFiltering && this._dataObject.state.isLoaded) {
            return this._dataObject.storage.data.length;
        }
        return await this.request('rowcount', pData);
    }

    async distinct(pOptions: RecordSourceOptions & IDistinctOptions) {
        if (!pOptions.skipClientSideHandlerCheck && this._dataObject?.clientSideFiltering && this._dataObject?.state.isLoaded) {
            // Use data stored in client
            let data: ItemModel = this._dataObject.storage.data;
            if (this.groupFormatFunction != null) {
                data = this._clientSideData;
            }
            data = this._clientSideFilter(pOptions, data as T[]);
            const fields = pOptions.fields.filter(field => field.groupByAggregate !== 'COUNT' && field.aggregate !== 'COUNT').map(field => field.name);

            const distinctGroups: Record<string, ItemModel & { Count: number }> = {};
            data.forEach((item: T) => {
                const key = fields.map(field => item[field]).join('');
                const group = distinctGroups[key];
                if (group) {
                    group.Count += 1;
                } else {
                    distinctGroups[key] = { Count: 1 };
                    fields.forEach(field => distinctGroups[key][field] = item[field]);
                }
            });

            const result = Object.values(distinctGroups);
            this._sortData(pOptions, result);
            return result;
        } else {
            // Request data from the database
            if (!this.utc) {

                pOptions.timezoneOffset = new Date().getTimezoneOffset();
            }

            return await this.request('retrieve', pOptions ?? {});
        }
    }

    async request<T1 extends CombinedRequestOptions<T> & RecordSourceOptions>(pType: RequestOperation, pData: T1, pHeaders?: Headers, pOptions?: RecordSourceOptions & RecordSourceCancelableEvent) {
        pData.operation = pType;


        if (!this.utc && pData && ['rowcount', 'retrieve', 'distinct'].includes(pData.operation)) {
            // Make sure timezone is always provided in retrieve-esque requests
            pData.timezoneOffset = pData?.timezoneOffset ?? -(new Date().getTimezoneOffset());
            pData.timezoneName = pData?.timezoneName ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        const requestKey = this._getRequestKey(pData);
        let abortController: AbortController | null = null;
        let vIfSkipabortCheck: boolean = pData.skipAbortCheck ?? pOptions?.skipAbortCheck ?? false;


        if (!vIfSkipabortCheck && (pType === 'retrieve' || pType === 'rowcount')) {
            if (this._requests.has(requestKey)) {
                const prevAbortController = this._requests.get(requestKey);
                prevAbortController?.abort();
                this._requests.delete(requestKey);
            }
            abortController = new AbortController();
            if (pData.getAbortController) {
                pData.getAbortController(abortController);
            }
            this._requests.set(requestKey, abortController);
        }

        const headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-NT-API': 'true'
        });
        pHeaders?.forEach((value, key) => { headers.append(key, value); });

        try {
            const useStream = pType == "retrieve" && pData && pData.onChunkLoaded;

            const result = await api.request({
                requestInfo: useStream?this._streamUrl:this._url,
                method: 'POST',
                headers: headers,
                body: JSON.stringify(pData),
                abortSignal: abortController?.signal,
                showErrorDialog: false,
                responseBodyHandler: useStream ? false : undefined
            });

            if (useStream) {
                const reader = (result as Response).body?.getReader();

                if (reader === undefined) {
                    throw new Error('Failed to read response');
                }

                const JsonDecoderStream = (await import('o365.modules.data.JsonDecoderStream.ts')).default;

                const decoder = JsonDecoderStream();

                const dataResult = new Array();

                while (true) {
                    const chunkResult = new Array();

                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }

                    decoder.decodeChunk(value, (item: any) => {
                        chunkResult.push(item);
                    });

                    pData!.onChunkLoaded!(chunkResult);

                    dataResult.push(...chunkResult);
                }

                reader.releaseLock();

                if (abortController) { this._requests.delete(requestKey); }

                return dataResult;
            }

            if (abortController) { this._requests.delete(requestKey); }

            return result;
        } catch (ex) {
            if (ex instanceof Response) {
                ex = await ex.json();
            }
            if (abortController?.signal.aborted) {
                const exceptionIsError = ex instanceof Error;
                throw new AbortError('Request aborted', exceptionIsError ? ex : undefined);
            } else if (ex?.hasOwnProperty('status')) {
                (ex as any).skipVueHandler = true;
                throw (ex);
            }
            if (abortController) {
                this._requests.delete(requestKey);
            }
            if (this._dataObject?.recordSource?.showToastsOnDataErrors ?? true) {
                alert(ex, ex?.errorType, { autohide: true });
            }

            if (typeof ex === 'string') {
                // TODO(Augustas): Replace with custom O365 error
                const error = new Error(ex);
                (error as any).skipVueHandler = true;
                throw error;
            } else {
                throw ex;
            }
        }
    }

    getAbortControllerForRequest<T extends IRequestOptions & RecordSourceOptions>(pOptions: T) {
        const key = this._getRequestKey(pOptions);
        return this._requests.get(key);
    }

    /** Get an unique key for the request based on the retreive options */
    protected _getRequestKey<T extends IRequestOptions & RecordSourceOptions>(pOptions: T) {
        return JSON.stringify({
            operation: pOptions.operation,
            whereClause: pOptions.whereClause,
            //filterString: pOptions.filterString,
            fields: pOptions.fields,
            viewName: pOptions.viewName,
            // masterDetailString: pOptions.masterDetailString
        });
    }

    /** Sort data on the client side */
    protected _sortData(pOptions: RecordSourceOptions, pData: any[]) {
        const sortOrder = pOptions.fields?.filter(x => x.sortOrder);

        if (sortOrder && sortOrder.length > 0) {
            sortOrder.sort((a, b) => a.sortOrder! - b.sortOrder!);
            sortOrder.forEach(item => {
                const field = this._dataObject.fields[item.name];
                const fieldType = field?.type ?? 'string';
                // TODO(Augustas): Check if alias can be aa value on item here
                pData.sort(this._sortByKey(item.name, item.sortDirection, fieldType));
            });
        }

        return pData;
    }

    /** Get a sort function for a specific item key */
    protected _sortByKey(pKey: string, pOrder: 'asc' | 'desc' | null | undefined, pType: FieldType) {
        return (_a: any, _b: any) => {
            let a = _a[pKey];
            let b = _b[pKey];
            if (pKey === 'string') {
                if (a && b) {
                    if (a !== null) { a = a.toString().toUpperCase(); }
                    if (b !== null) { b = b.toString().toUpperCase(); }
                }
            } else if (['datetime', 'date'].includes(pType)) {
                a = new Date(a as string);
                b = new Date(b as string);
            }
            switch (pType) {
                case 'number':
                    return pOrder === 'asc' ? (a - b) : (b - a);
                case 'string':
                case 'date':
                case 'datetime':
                case 'uniqueidentifier':
                    return pOrder === 'asc' ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);
                default:
                    return 0;
            }
        };
    }

    /** Apply client side filters on a data array  */
    protected _clientSideFilter(pOptions: RecordSourceOptions, pData: T[]) {
        let data = pData;
        if (pOptions.masterDetailObject) {
            data = applyFilterObject(pData, pOptions.masterDetailObject) ?? [];
        }
        if (pOptions.whereObject) {
            data = applyFilterObject(pData, pOptions.whereObject) ?? [];
        }
        if (pOptions.filterObject) {
            data = applyFilterObject(pData, pOptions.filterObject) ?? [];
        }
        return data;
    }

    setClientSideData(pData: T[]) {
        this._clientSideData = pData;
    }
}

/** Possible request operations */
export type RequestOperation = 'retrieve' | 'create' | 'update' | 'destroy' | 'rowcount' | 'softDelete';
/** Base request options */
export interface IRequestOptions {
    operation?: RequestOperation,
    fields?: DataObjectDefinitionFieldType[],
    timezoneOffset?: number,
}
/** Retreive options */
export interface IRetrieveOptions<T extends ItemModel = ItemModel> extends IRequestOptions {
    fields: DataObjectDefinitionFieldType[];
    timezoneOffset: number;
    /**
     * Skip client side filtering and force retrieve from 
     * the database, applies when clientSideFiltering is enabled on the DataObject
     */
    skipClientSideHandlerCheck?: boolean,
    onChunkLoaded?: (pItems: T[]) => void,
    getAbortController?: (pController: AbortController) => void,
}
/** Create options */
export interface ICreateOptions extends IRequestOptions {
    bulk?: boolean;
}
/** Update options */
export interface IUpdateOptions extends IRequestOptions { 
    bulk?: boolean;
    key?: string;
    values?: {
        data?: object
    }
}
/** Destroy options */
export interface IDestroyOptions extends IRequestOptions {
    /** Is bulk destroy operation */
    bulk?: boolean;
    /** Array of PrimKeys for bulk destroy */
    valuelist?: string[];
}
/** Distinct options */
export interface IDistinctOptions extends IRequestOptions {
    fields: DataObjectDefinitionFieldType[];
    timezoneOffset?: number;
    skipClientSideHandlerCheck?: boolean,
}
/** Rowcount options */
export interface IRowCountOptions extends IRequestOptions {
    timezoneOffset: number;
    timeout: number;
}

type CombinedRequestOptions<T extends ItemModel = ItemModel> = Partial<IRetrieveOptions<T>> & Partial<IDestroyOptions> & Partial<ICreateOptions> & Partial<IUpdateOptions>;
/** Custom AbortError class to esnure all browsers are supported */
export class AbortError extends Error {
    skipVueHandler = true;
    constructor(message: string, cause?: Error) {
        super(message, {
            cause: cause
        });
    }
}